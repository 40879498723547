import React, { useState } from "react";
import "./App.css";
import Nav from "./nav/nav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Solutions from "./solutions/solutions";
import SideMenu from "./sideMenu/sideMenu";
import Privacy from "./privacy/privacy";
import Terms from "./terms/terms";
import Pricing from "./pricing/pricing";
import News from "./news/news";
import Home from "./home/home";
import PageNotFound from "./notFound/notFound";
import Foot from "./footer/footer";

function App() {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const toggleSideMenu = () => {
    setShowSideMenu((prev) => !prev);
  };

  const hideSideMenu = () => {
    setShowSideMenu(false);
  };

  return (
    <Router>
      <main>
        <Nav onHamburgerClick={toggleSideMenu} />
        {showSideMenu && <SideMenu />}
        <Routes>
          <Route path="/" element={<Home onClick={hideSideMenu} />} />
          <Route
            path="/solutions/"
            element={<Solutions onClick={hideSideMenu} />}
          />
          {/* <Route path="/about/" element={<About onClick={hideSideMenu} />} /> */}
          <Route
            path="/pricing/"
            element={<Pricing onClick={hideSideMenu} />}
          />
          <Route path="/news/" element={<News onClick={hideSideMenu} />} />
          <Route
            path="/privacy/"
            element={<Privacy onClick={hideSideMenu} />}
          />
          <Route path="/terms/" element={<Terms onClick={hideSideMenu} />} />
          <Route path="*" element={<PageNotFound onClick={hideSideMenu} />} />
        </Routes>
        <Foot />
      </main>
    </Router>
  );
}

export default App;

import { Helmet } from "react-helmet";

export default function PageNotFound(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Page Not Found | GenAIOps & LLMOps by Dataster</title>
        <meta name="description" content="Page not found." />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
            gap: "4rem",
          }}
        >
          <img src="/notFound.png" alt="not found" />
        </div>
      </div>
    </>
  );
}

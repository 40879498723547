import React from "react";
import { Helmet } from "react-helmet";

export default function Terms(props) {
  const { onClick } = props;

  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Dataster's User Agreement</title>
        <meta
          name="description"
          content="Review Dataster's Terms and Conditions to understand your rights and responsibilities. Learn about our policies, user agreements, and legal guidelines."
        />
        <link rel="canonical" href="https://www.dataster.com/terms/" />
      </Helmet>
      <div class="WordSection1" onClick={onClick} id="top">
        <div>
          <h1 class="MsoTitle">Terms and Conditions</h1>
        </div>

        <h2>1. Introduction</h2>

        <p class="MsoNormal">
          Welcome to Dataster! These terms and conditions outline the rules and
          regulations for the use of Dataster's services.
        </p>

        <h2>2. Acceptance of Terms</h2>

        <p class="MsoNormal">
          By accessing and using Dataster, you accept and agree to be bound by
          these terms and conditions. If you do not agree with any part of these
          terms, you must not use our services.
        </p>

        <h2>3. Description of Services</h2>

        <p class="MsoNormal">
          Dataster provides services and functionalities to help developers
          build AI applications. Our services include a range of tools and
          resources to assist in the development process.
        </p>

        <h2>4. User Accounts</h2>

        <p class="MsoNormal">
          To access certain features, you must create an account. You are
          responsible for maintaining the confidentiality of your account
          credentials and for all activities that occur under your account.
        </p>

        <h2>5. Free Tier and Credits</h2>

        <p class="MsoNormal">
          Signing up and starting to use Dataster is free. We provide a free
          tier with $1 worth of credits upon sign-up to allow new users to try
          the application. Users can recharge their balance in $25 increments.
          New Users are those who have not previously had a Dataster account and
          received $1 worth of credit, regardless of whether the credit was used
          or not.
        </p>

        <h2>6. Payment and Refunds</h2>

        <p class="MsoNormal">
          We accept payments via credit card and Cash App Pay. While we do not
          offer a money-back guarantee for partially used $25 increments, we
          will refund unused $25 increments upon request minus a 10% processing
          fee.
        </p>

        <h2>7. Usage Restrictions</h2>

        <p class="MsoNormal">
          Users are prohibited from using Dataster for illegal activities or to
          generate harmful or offensive content. Violation of this clause may
          result in account termination.
        </p>

        <h2>8. Privacy Policy</h2>

        <p class="MsoNormal">
          Our privacy policy, available at http://www.dataster.com/privacy.html,
          explains how we collect, use, and protect your data.
        </p>

        <h2>9. User Data and Security</h2>

        <p class="MsoNormal">
          Users are responsible for maintaining the security of their login
          credentials. Any breach of security must be reported immediately.
        </p>

        <h2>10. Intellectual Property</h2>

        <p class="MsoNormal">
          All content and software provided by Dataster are protected by
          intellectual property laws. Users may not use our content or software
          without permission.
        </p>

        <h2>11. Support</h2>

        <p class="MsoNormal">
          We provide support via email at support@dataster.com. Users can also
          send messages through the application by going to settings and
          contacting support.
        </p>

        <h2>12. Limitation of Liability</h2>

        <p class="MsoNormal">
          Dataster LLC will not be liable for any indirect, incidental, special,
          consequential, or punitive damages arising from the use of our
          services.
        </p>

        <h2>13. Termination</h2>

        <p class="MsoNormal">
          Accounts can be terminated if fraud is suspected, if a user tries to
          access unpaid services, or if the application is used unlawfully or to
          generate harmful or offensive content.
        </p>

        <h2>14. Modifications to Terms</h2>

        <p class="MsoNormal">
          We may update these terms from time to time. Users will be notified
          via email of any changes, which will include a link to the new terms
          and conditions.
        </p>

        <h2>15. Governing Law and Dispute Resolution</h2>

        <p class="MsoNormal">
          These terms are governed by the laws of the State of New York. Any
          disputes will be resolved through arbitration in New York, NY.
        </p>
      </div>
    </>
  );
}

import { Helmet } from "react-helmet";

export default function About(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster News - Latest Updates & Feature Announcements</title>
        <meta
          name="description"
          content="Stay informed with the latest news and updates on GenAIOps & LLMOps. Read about our software updates, new features, and capabilities in LLMOps & GenAIOps for AI developers."
        />
        <link rel="canonical" href="https://www.dataster.com/news/" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster News</h1>
        <span>
          Dataster helps you build Generative AI applications with better
          accuracy and lower latency.
        </span>
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <span style={{ fontSize: "larger", textAlign: "right" }}>
              October 2024
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Jamba 1.5 Mini on AWS is now on Dataster</h2>
            </div>
            <div>
              <p>
                Today, we're thrilled to unveil the latest addition to our model
                gallery: <code>ai21.jamba-1-5-mini-v1:0</code>! This
                cutting-edge language model is now available as an AWS-hosted
                option. Plus, you still have the flexibility to bring your own
                AWS-hosted Jamba 1.5 Mini if you prefer. Dive in and explore the
                possibilities!
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>OpenAI GPT-4o-mini is now on Dataster</h2>
            </div>
            <div>
              <p>
                We're excited to announce the addition of another powerful
                language model to our collection:{" "}
                <code>gpt-4o-mini-2024-07-18</code>. As always, you have the
                flexibility to bring your own OpenAI GPT-4o mini if you prefer.
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Language Models and RAG Auto evaluation</h2>
            </div>
            <div>
              <p>
                Dataster has a human evaluation feature where users can rate a
                list of completions as good or bad, or alternatively, on a scale
                from 1 to 5. Today, we are excited to release a new capability
                in beta that automates the binary evaluation process. Dataster
                can now automatically rate all your candidate models and RAGs
                against your use case, helping you make the best decisions when
                building your GenAI-backed application.
              </p>
            </div>
          </div>

          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>User Prompt and Ground Truth</h2>
            </div>
            <div>
              <p>
                In today’s release, we are adding the capability to optionally
                save a ground truth answer corresponding to a user question in a
                user prompt. This will underpin our upcoming Auto Eval feature,
                which will allow you to launch automated evaluation jobs to
                determine how your RAGs perform against a large and
                representative set of user questions. This will supplement the
                existing Human Eval feature, which lets users manually assign
                grades to each response and rank their models and RAGs for a
                specific use case.
              </p>
            </div>
          </div>
          <div className="page-container__about__desc">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>OpenAI on Dataster</h2>
            </div>
            <div>
              <p>
                Today, we are adding support for <code>gpt-4o-2024-08-06</code>{" "}
                hosted on OpenAI. This model was previously supported on
                Dataster only as an Azure OpenAI hosted model. Users can now
                compare the model’s performance across both providers. With this
                release, Dataster now supports 10 models across three different
                providers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { HashLink } from "react-router-hash-link";

export default function Foot(props) {
  return (
    <footer className="footer">
      <div className="footer_inner">
        <span>contact@dataster.com</span>
        <span>
          <HashLink smooth to="/terms/#top">
            Terms of Service
          </HashLink>
        </span>
        <span>
          <HashLink to="/privacy/#top">Privacy Policy</HashLink>
        </span>
        <span>&copy; 2024 Dataster, LLC</span>
      </div>
    </footer>
  );
}

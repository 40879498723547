import React from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "../imageGallery/imageGallery";
import { HashLink } from "react-router-hash-link";

export default function Home(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>
          LLMOps & GenAIOps Solutions for AI Application Developers by Dataster
        </title>
        <meta
          name="description"
          content="Dataster: Your LLMOps & GenAIOps partner. AI model selection, grounding data, prompt engineering, LLM comparison, LLM evaluation and LLM benchmarking."
        />
        <link rel="canonical" href="https://www.dataster.com" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster - LLMOps & GenAIOps for AI Developers</h1>
        <span>
          Dataster helps you build Generative AI applications with better
          accuracy and lower latency.
        </span>

        <div className="page-container__main">
          <div
            className="cta"
            style={{ justifyContent: "center", marginBottom: "1rem" }}
          >
            <div className="signup">
              <a href="https://app.dataster.com/signup">Try Free</a>
            </div>
            {/* <div>
              <a href="https://app.dataster.com/login">Sign In</a>
            </div> */}
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div className="page-container__main__desc">
              <h2>
                The Need for Use Case Specific LLMOps and GenAIOps Tooling
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  textAlign: "start",
                }}
              >
                <p>
                  When developing a GenAI-backed application, several critical
                  questions inevitably arise:{" "}
                  <strong>
                    Which model should be used? What data will best ground the
                    model? Which vector store is most suitable?
                  </strong>{" "}
                  The array of choices provided by numerous vendors can make
                  identifying the optimal combination feel like searching for a
                  needle in a haystack. Navigating this vast and rapidly
                  expanding landscape is indeed challenging.
                </p>

                <p>
                  As an initial guide, one might consider open leaderboards and
                  benchmarks such as MMLU or ARC, which rank and compare models
                  directionally. While these are useful,{" "}
                  <strong>
                    they often lack the specificity needed for particular use
                    cases
                  </strong>
                  . A model's top ranking on a standard benchmark does not
                  necessarily guarantee the best performance for your specific
                  application, especially once it's grounded in your data and
                  handling your user prompts.
                </p>
                <p>
                  The same reasoning applies to latency. A model that returns
                  tokens faster in response to generic prompts in a controlled
                  lab environment may not exhibit the same performance in a real
                  production setting, where it interacts with your application
                  and data. Therefore,{" "}
                  <strong>
                    it's crucial to have use case-specific LLMOps and GenAIOps
                    tooling
                  </strong>{" "}
                  to accurately assess and select the best components for your
                  GenAI application.
                </p>
                <p>
                  <strong>
                    Take a look at our{" "}
                    <HashLink smooth to="/solutions/#top">
                      solutions
                    </HashLink>
                    !
                  </strong>
                </p>
                <p>or</p>
              </div>
            </div>
          </div>
          <ImageGallery
            image="/GenAIOpsTenets.png"
            alt="The LLMOps and GenAIOps tenets"
            title="What is GenAIOps?"
          />
          <div style={{ marginTop: "1rem" }}>
            <div className="page-container__main__desc">
              <h2>Understanding LLMOps & GenAIOps</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  textAlign: "start",
                }}
              >
                <p>
                  <strong>GenAIOps (Generative AI Operations)</strong> is a
                  transformative framework that revolutionizes the development,
                  deployment, and management of generative AI solutions. It
                  integrates seven key tenets:{" "}
                  <strong>
                    Strategy, AI Asset Management, Trust, Model Selection,
                    Grounding Data, Evaluation, and Prompt Engineering
                  </strong>
                  .
                </p>

                <p>
                  By leveraging these principles, GenAIOps ensures a strategic
                  approach to AI deployment, meticulous management of AI assets,
                  and the establishment of trust through robust security and
                  ethical practices. It emphasizes the importance of
                  benchmarking, selecting the right models, grounding them in
                  high-quality data, and continuously evaluating their
                  performance. Additionally, it focuses on prompt engineering to
                  fine-tune AI interactions.
                </p>
                <p>
                  GenAIOps builds upon the foundation of{" "}
                  <strong>LLMOps (Large Language Model Operations)</strong>,
                  which is a set of practices specifically designed for the
                  operationalization of large language models, by extending its
                  capabilities to encompass the broader scope of generative AI.
                  This holistic approach not only addresses the unique
                  challenges of generative AI but also drives innovation,
                  efficiency, and strategic advantage across enterprise
                  environments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import ImageGallery from "../imageGallery/imageGallery";

export default function Solutions(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>
          LLMOps & GenAIOps Solutions - Dataster Features & Capabilities
        </title>
        <meta
          name="description"
          content="Explore Dataster's LLMOps & GenAIOps solutions and features for AI model selection, grounding data, prompt engineering, LLM comparison, benchmarking, and evaluation."
        />
        <link rel="canonical" href="https://www.dataster.com/solutions/" />
      </Helmet>
      <div className="page-container" onClick={onClick} id="top">
        <h1>Dataster - LLMOps & GenAIOps Solutions</h1>
        <span>
          Dataster helps you build Generative AI applications with better
          accuracy and lower latency.
        </span>
        <div className="page-container__item">
          <ImageGallery
            image="/promptGallery.jpg"
            alt="prompt gallery"
            title="Prompt management"
          />
          <div className="page-container__item__desc">
            <h2>Effortless prompt management</h2>
            <div>
              <p>
                Dataster offers a versatile prompt gallery that supports system,
                user, and assistant prompts. Organize your prompts by use case
                and evaluate them either individually or collectively. With a
                single click, test them in the LLM playground or use a prompt
                set to measure the average latency of models on your specific
                use case.
              </p>
            </div>
          </div>
        </div>

        <div className="page-container__item">
          <ImageGallery
            image="/llmGallery.jpg"
            alt="LLM gallery"
            title="LLM management"
          />
          <div className="page-container__item__desc">
            <h2>Multicloud LLM management</h2>
            <div>
              <p>
                Seamlessly integrate and oversee models across multiple cloud
                providers in one place with Dataster's LLM library, ensuring
                flexibility and efficiency. This capability allows for
                streamlined operations and cost management, empowering you to
                leverage the best resources for your AI applications.
              </p>
            </div>
          </div>
        </div>

        <div className="page-container__item">
          <ImageGallery
            image="/critique.jpg"
            alt="prompt critique"
            title="System prompt suggestions"
          />
          <div className="page-container__item__desc">
            <h2>Iterative prompt engineering</h2>
            <div>
              <p>
                Dataster offers insightful recommendations for your system
                prompts, helping you identify and implement prompt engineering
                best practices that might have been overlooked. Additionally,
                Dataster provides a grading system with each iteration, ensuring
                that every step you take moves your application in the right
                direction.
              </p>
            </div>
          </div>
        </div>

        <div className="page-container__item">
          <ImageGallery
            image="/racetrack.jpg"
            alt="race track"
            title="Model speed comparison"
          />
          <div className="page-container__item__desc">
            <h2>RAG and LLM latency comparison</h2>
            <div>
              <p>
                Put your language models and RAGs to the test on Dataster's
                racetrack. Compare up to 8 models and RAGs, evaluating their
                latency with your prompts. Dataster lets you see which model
                delivers the lowest latency, helping you choose the fastest
                option for your use case.
              </p>
            </div>
          </div>
        </div>

        <div className="page-container__item">
          <ImageGallery
            image="/latencyjob.jpg"
            alt="latency job"
            title="Model and RAG latency job"
          />
          <div className="page-container__item__desc">
            <h2>RAG and LLM benchmark for latency</h2>
            <div>
              <p>
                Systematically test your entire use case against various
                combinations of LLMs, RAGs, and system prompts with Dataster.
                Run latency benchmark jobs to discover the optimal combination
                that delivers the lowest latency on your specific use case,
                ensuring the best experience for your users.
              </p>
            </div>
          </div>
        </div>

        <div className="page-container__item">
          <ImageGallery
            image="/humanEval.jpg"
            alt="Human evaluation"
            title="Model and RAG evaluation job"
          />
          <div className="page-container__item__desc">
            <h2>Manual or automatic, RAG and LLM benchmark for performance</h2>
            <div>
              <p>
                Run performance benchmark jobs based on output quality. Conduct
                manual and automated evaluation jobs using various LLMs, RAGs,
                and system prompts. Assess the accuracy of responses and develop
                higher-performing systems tailored to your use cases.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
